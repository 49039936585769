const Ordering = {
  Less: "less",
  Equal: "equal",
  Greater: "greater"
};

var __accessCheck$2 = (obj, member, msg) => {
  if (!member.has(obj))
    throw TypeError("Cannot " + msg);
};
var __privateGet$2 = (obj, member, getter) => {
  __accessCheck$2(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd$2 = (obj, member, value) => {
  if (member.has(obj))
    throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet$2 = (obj, member, value, setter) => {
  __accessCheck$2(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};
var __privateMethod$1 = (obj, member, method) => {
  __accessCheck$2(obj, member, "access private method");
  return method;
};
var _compareFn, _heap, _heapifyDown, heapifyDown_fn, _heapifyUp, heapifyUp_fn;
class MinHeap {
  constructor({ compareFn }) {
    __privateAdd$2(this, _heapifyDown);
    __privateAdd$2(this, _heapifyUp);
    __privateAdd$2(this, _compareFn, void 0);
    __privateAdd$2(this, _heap, void 0);
    __privateSet$2(this, _compareFn, compareFn);
    __privateSet$2(this, _heap, []);
  }
  insert(value) {
    __privateGet$2(this, _heap).push(value);
    __privateMethod$1(this, _heapifyUp, heapifyUp_fn).call(this);
  }
  pop() {
    const item = __privateGet$2(this, _heap)[0];
    if (__privateGet$2(this, _heap)[__privateGet$2(this, _heap).length - 1]) {
      __privateGet$2(this, _heap)[0] = __privateGet$2(this, _heap)[__privateGet$2(this, _heap).length - 1];
      __privateGet$2(this, _heap).pop();
    }
    __privateMethod$1(this, _heapifyDown, heapifyDown_fn).call(this);
    return item;
  }
  peek() {
    return __privateGet$2(this, _heap)[0];
  }
  delete(value) {
    const index = __privateGet$2(this, _heap).indexOf(value);
    if (index === -1) {
      return;
    }
    swap(__privateGet$2(this, _heap), index, __privateGet$2(this, _heap).length - 1);
    __privateGet$2(this, _heap).pop();
    __privateMethod$1(this, _heapifyDown, heapifyDown_fn).call(this);
  }
  clear() {
    __privateSet$2(this, _heap, []);
  }
  get size() {
    return __privateGet$2(this, _heap).length;
  }
}
_compareFn = new WeakMap();
_heap = new WeakMap();
_heapifyDown = new WeakSet();
heapifyDown_fn = function() {
  let index = 0;
  while (hasLeftChild(index, __privateGet$2(this, _heap).length)) {
    let smallerChildIndex = getLeftChildIndex(index);
    if (hasRightChild(index, __privateGet$2(this, _heap).length) && __privateGet$2(this, _compareFn).call(this, rightChild(__privateGet$2(this, _heap), index), leftChild(__privateGet$2(this, _heap), index)) === Ordering.Less) {
      smallerChildIndex = getRightChildIndex(index);
    }
    if (__privateGet$2(this, _compareFn).call(this, __privateGet$2(this, _heap)[index], __privateGet$2(this, _heap)[smallerChildIndex]) === Ordering.Less) {
      break;
    } else {
      swap(__privateGet$2(this, _heap), index, smallerChildIndex);
    }
    index = smallerChildIndex;
  }
};
_heapifyUp = new WeakSet();
heapifyUp_fn = function() {
  let index = __privateGet$2(this, _heap).length - 1;
  while (hasParent(index) && __privateGet$2(this, _compareFn).call(this, __privateGet$2(this, _heap)[index], parent(__privateGet$2(this, _heap), index)) === Ordering.Less) {
    swap(__privateGet$2(this, _heap), index, getParentIndex(index));
    index = getParentIndex(index);
  }
};
function getLeftChildIndex(index) {
  return 2 * index + 1;
}
function getRightChildIndex(index) {
  return 2 * index + 2;
}
function getParentIndex(index) {
  return Math.floor((index - 1) / 2);
}
function hasLeftChild(index, size) {
  return getLeftChildIndex(index) < size;
}
function hasRightChild(index, size) {
  return getRightChildIndex(index) < size;
}
function hasParent(index) {
  return index > 0;
}
function leftChild(heap, index) {
  return heap[getLeftChildIndex(index)];
}
function rightChild(heap, index) {
  return heap[getRightChildIndex(index)];
}
function parent(heap, index) {
  return heap[getParentIndex(index)];
}
function swap(heap, a, b) {
  const tmp = heap[a];
  heap[a] = heap[b];
  heap[b] = tmp;
}

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __accessCheck$1 = (obj, member, msg) => {
  if (!member.has(obj))
    throw TypeError("Cannot " + msg);
};
var __privateGet$1 = (obj, member, getter) => {
  __accessCheck$1(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd$1 = (obj, member, value) => {
  if (member.has(obj))
    throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet$1 = (obj, member, value, setter) => {
  __accessCheck$1(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};
var _a, _promise, _resolve, _reject;
class Deferred {
  constructor() {
    __publicField(this, _a, "Deferred");
    __privateAdd$1(this, _promise, void 0);
    __privateAdd$1(this, _resolve, void 0);
    __privateAdd$1(this, _reject, void 0);
    __privateSet$1(this, _promise, new Promise((resolve, reject) => {
      __privateSet$1(this, _resolve, resolve);
      __privateSet$1(this, _reject, reject);
    }));
  }
  then(onfulfilled, onrejected) {
    return Promise.prototype.then.apply(__privateGet$1(this, _promise), [onfulfilled, onrejected]);
  }
  catch(onrejected) {
    return Promise.prototype.catch.apply(__privateGet$1(this, _promise), [onrejected]);
  }
  finally(onfinally) {
    return Promise.prototype.finally.apply(__privateGet$1(this, _promise), [onfinally]);
  }
  resolve(value) {
    __privateGet$1(this, _resolve).call(this, value);
  }
  reject(reason) {
    __privateGet$1(this, _reject).call(this, reason);
  }
  getPromise() {
    return __privateGet$1(this, _promise);
  }
}
_a = Symbol.toStringTag;
_promise = new WeakMap();
_resolve = new WeakMap();
_reject = new WeakMap();

var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj))
    throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj))
    throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};
var __privateMethod = (obj, member, method) => {
  __accessCheck(obj, member, "access private method");
  return method;
};
var _pending, _queue, _timeoutId, _performWork, performWork_fn, _updateContainerWithMessage, updateContainerWithMessage_fn;
const DEFAULT_THROTTLE_DELAY_MS = 150;
class LiveRegionElement extends HTMLElement {
  constructor() {
    super();
    __privateAdd(this, _performWork);
    __privateAdd(this, _updateContainerWithMessage);
    /**
     * A flag to indicate if a message has been announced and we are currently
     * waiting for the delay to pass before announcing the next message.
     */
    __privateAdd(this, _pending, void 0);
    /**
     * A priority queue to store messages to be announced by the live region.
     */
    __privateAdd(this, _queue, void 0);
    /**
     * The id for a timeout being used by the live region to either wait until the
     * next message or wait until the delay has passed before announcing the next
     * message
     */
    __privateAdd(this, _timeoutId, void 0);
    if (!this.shadowRoot) {
      const template2 = getTemplate();
      const shadowRoot = this.attachShadow({ mode: "open" });
      shadowRoot.appendChild(template2.content.cloneNode(true));
    }
    __privateSet(this, _pending, false);
    __privateSet(this, _timeoutId, null);
    __privateSet(this, _queue, new MinHeap({
      compareFn: compareMessages
    }));
  }
  /**
   * The delay in milliseconds to wait between announcements. This helps to
   * prevent announcements getting dropped if multiple are made at the same time.
   */
  get delay() {
    const value = this.getAttribute("delay");
    if (value) {
      return parseInt(value, 10);
    }
    return DEFAULT_THROTTLE_DELAY_MS;
  }
  set delay(value) {
    this.setAttribute("delay", `${value}`);
  }
  /**
   * Announce a message using a live region with a corresponding politeness
   * level.
   */
  announce(message, options = {}) {
    const { delayMs, politeness = "polite" } = options;
    const now = Date.now();
    const deferred = new Deferred();
    const item = {
      deferred,
      politeness,
      contents: message,
      scheduled: delayMs !== void 0 ? now + delayMs : now
    };
    __privateGet(this, _queue).insert(item);
    __privateMethod(this, _performWork, performWork_fn).call(this);
    return {
      ...deferred.getPromise(),
      cancel: () => {
        __privateGet(this, _queue).delete(item);
        deferred.resolve();
      }
    };
  }
  /**
   * Announce a message using the text content of an element with a
   * corresponding politeness level
   */
  announceFromElement(element, options) {
    const textContent = getTextContent(element);
    if (textContent !== "") {
      return this.announce(textContent, options);
    }
    const promise = Promise.resolve();
    return {
      ...promise,
      cancel: noop
    };
  }
  getMessage(politeness = "polite") {
    const container = this.shadowRoot?.getElementById(politeness);
    if (!container) {
      throw new Error("Unable to find container for message");
    }
    return container.textContent;
  }
  /**
   * Prevent pending messages from being announced by the live region.
   */
  clear() {
    if (__privateGet(this, _timeoutId) !== null) {
      clearTimeout(__privateGet(this, _timeoutId));
      __privateSet(this, _timeoutId, null);
    }
    __privateGet(this, _queue).clear();
  }
}
_pending = new WeakMap();
_queue = new WeakMap();
_timeoutId = new WeakMap();
_performWork = new WeakSet();
performWork_fn = function() {
  if (__privateGet(this, _pending)) {
    return;
  }
  let message = __privateGet(this, _queue).peek();
  if (!message) {
    return;
  }
  if (__privateGet(this, _timeoutId) !== null) {
    clearTimeout(__privateGet(this, _timeoutId));
    __privateSet(this, _timeoutId, null);
  }
  const now = Date.now();
  if (message.scheduled <= now) {
    message = __privateGet(this, _queue).pop();
    if (message) {
      __privateMethod(this, _updateContainerWithMessage, updateContainerWithMessage_fn).call(this, message);
    }
    __privateMethod(this, _performWork, performWork_fn).call(this);
    return;
  }
  const timeout = message.scheduled - now;
  __privateSet(this, _timeoutId, window.setTimeout(() => {
    __privateSet(this, _timeoutId, null);
    __privateMethod(this, _performWork, performWork_fn).call(this);
  }, timeout));
};
_updateContainerWithMessage = new WeakSet();
updateContainerWithMessage_fn = function(message) {
  __privateSet(this, _pending, true);
  const { contents, deferred, politeness } = message;
  const container = this.shadowRoot?.getElementById(politeness);
  if (!container) {
    __privateSet(this, _pending, false);
    throw new Error(`Unable to find container for message. Expected a container with id="${politeness}"`);
  }
  if (container.textContent === contents) {
    container.textContent = `${contents}\xA0`;
  } else {
    container.textContent = contents;
  }
  if (__privateGet(this, _timeoutId) !== null) {
    clearTimeout(__privateGet(this, _timeoutId));
  }
  deferred.resolve();
  if (this.delay > 0) {
    __privateSet(this, _timeoutId, window.setTimeout(() => {
      __privateSet(this, _timeoutId, null);
      __privateSet(this, _pending, false);
      __privateMethod(this, _performWork, performWork_fn).call(this);
    }, this.delay));
  } else {
    __privateSet(this, _timeoutId, null);
    __privateSet(this, _pending, false);
    __privateMethod(this, _performWork, performWork_fn).call(this);
  }
};
function getTextContent(element) {
  let value = "";
  if (element.hasAttribute("aria-label")) {
    value = element.getAttribute("aria-label");
  } else if (element.innerText) {
    value = element.innerText;
  } else if (element.textContent) {
    value = element.textContent;
  }
  return value ? value.trim() : "";
}
let template = null;
const templateContent = `
<style>
:host {
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
</style>
<div id="polite" aria-live="polite" aria-atomic="true"></div>
<div id="assertive" aria-live="assertive" aria-atomic="true"></div>
`;
function getTemplate() {
  if (template) {
    return template;
  }
  template = document.createElement("template");
  template.innerHTML = templateContent;
  return template;
}
function compareMessages(a, b) {
  if (a.politeness === b.politeness) {
    if (a.scheduled === b.scheduled) {
      return Ordering.Equal;
    }
    if (a.scheduled < b.scheduled) {
      return Ordering.Less;
    }
    return Ordering.Greater;
  }
  if (a.politeness === "assertive" && b.politeness !== "assertive") {
    return Ordering.Less;
  }
  if (a.politeness !== "assertive" && b.politeness === "assertive") {
    return Ordering.Greater;
  }
  return Ordering.Equal;
}
function noop() {
}

if (!customElements.get("live-region")) {
  customElements.define("live-region", LiveRegionElement);
}

function announce(message, options = {}) {
  let liveRegion = findLiveRegion(options.from);
  if (!liveRegion) {
    liveRegion = document.createElement("live-region");
    if (options.appendTo) {
      options.appendTo.appendChild(liveRegion);
    } else {
      const container = getLiveRegionContainer(options.from);
      container.appendChild(liveRegion);
    }
    if (process.env.NODE_ENV === "test") {
      return liveRegion.announce(message, options);
    }
    let canceled = false;
    let cancel = () => {
      canceled = true;
    };
    const promise = wait(REGISTER_WAIT_MS).then(() => {
      if (!canceled) {
        const result = liveRegion.announce(message, options);
        cancel = result.cancel;
        return result;
      }
    });
    return {
      ...promise,
      cancel: () => {
        cancel();
      }
    };
  }
  return liveRegion.announce(message, options);
}
function announceFromElement(element, options = {}) {
  let liveRegion = findLiveRegion(options.from);
  if (!liveRegion) {
    liveRegion = document.createElement("live-region");
    if (options.appendTo) {
      options.appendTo.appendChild(liveRegion);
    } else {
      const container = getLiveRegionContainer(options.from);
      container.appendChild(liveRegion);
    }
    if (process.env.NODE_ENV === "test") {
      return liveRegion.announceFromElement(element, options);
    }
    let canceled = false;
    let cancel = () => {
      canceled = true;
    };
    const promise = wait(REGISTER_WAIT_MS).then(() => {
      if (!canceled) {
        const result = liveRegion.announceFromElement(element, options);
        cancel = result.cancel;
        return result;
      }
    });
    return {
      ...promise,
      cancel: () => {
        cancel();
      }
    };
  }
  return liveRegion.announceFromElement(element, options);
}
function findLiveRegion(from) {
  let liveRegion = null;
  liveRegion = from ? getClosestLiveRegion(from) : null;
  if (liveRegion !== null) {
    return liveRegion;
  }
  const container = getLiveRegionContainer(from);
  liveRegion = container.querySelector("live-region");
  if (liveRegion !== null) {
    return liveRegion;
  }
  return null;
}
function getClosestLiveRegion(from) {
  const dialog = from.closest("dialog");
  let current = from;
  while (current = current.parentElement) {
    if (dialog && !dialog.contains(current)) {
      break;
    }
    for (const child of current.childNodes) {
      if (child instanceof LiveRegionElement) {
        return child;
      }
    }
  }
  return null;
}
function getLiveRegionContainer(from) {
  let container = document.body;
  if (from) {
    const dialog = from.closest("dialog");
    if (dialog) {
      container = dialog;
    }
  }
  return container;
}
const REGISTER_WAIT_MS = 150;
function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export { LiveRegionElement, announce, announceFromElement, templateContent };
